module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.meivcoreambiente.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#3e78ba","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e767303fefe950a623122d2f35e58f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pt","en"],"defaultLanguage":"pt","generateDefaultLanguagePage":false,"redirect":false,"siteUrl":"https://meivcoreambiente.com/","trailingSlash":"never","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/services/","getLanguageFromPath":true,"excludeLanguages":["pt"]},{"matchPath":"/servicos/(.*)","getLanguageFromPath":true,"excludeLanguages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.index":"/","urls.amianto":"asbestos","urls.contactos":"contacts","urls.grupo":"group","urls.legislacao":"legislation","urls.politica-privacidade":"privacy-policy","urls.servicos":"services","urls.sobre-nos":"about-us","urls.remocao-amianto":"asbestos-removal","urls.saneamento-ambiental-estruturas-industriais":"industrial-building-structure-remediation","urls.execucao-coberturas-painel-sandwich":"installation-of-sandwich-panel-roofing","urls.execucao-revestimento-fachadas":"facade-cladding","urls.execucao-coberturas-tipo-deck":"deck-type-roofing","urls.impermeabilizacao-pintura-edificios-edificios":"waterproofing-painting-of-buildings","urls.instalacao-de-paineis-fotovoltaicos":"photovoltaic-panel-installation"},"pt":{"urls.index":"/","urls.amianto":"amianto","urls.contactos":"contactos","urls.grupo":"grupo","urls.legislacao":"legislacao","urls.politica-privacidade":"politica-privacidade","urls.servicos":"servicos","urls.sobre-nos":"sobre-nos","urls.remocao-amianto":"remocao-amianto","urls.saneamento-ambiental-estruturas-industriais":"saneamento-ambiental-estruturas-industriais","urls.execucao-coberturas-painel-sandwich":"execucao-coberturas-painel-sandwich","urls.execucao-revestimento-fachadas":"execucao-revestimento-fachadas","urls.execucao-coberturas-tipo-deck":"execucao-coberturas-tipo-deck","urls.impermeabilizacao-pintura-edificios-edificios":"impermeabilizacao-pintura-edificios-edificios","urls.instalacao-de-paineis-fotovoltaicos":"instalacao-de-paineis-fotovoltaicos"}},"defaultLocale":"pt","prefix":"urls."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
